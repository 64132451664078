import Vue from 'vue'

export default {

  getRegionDomain (shipperId) {
    return Vue.http.post('reg/getRegionDomain', { shipperId: shipperId})
  },
  saveRegionDomain(currentShipper,file,idcard_file,road_file,open_file,idcard_back_file,tax_file,taxpayer_file){
    currentShipper.redirectUrl = ""
    return Vue.http.post('reg/saveRegionDomain', { shipperInfo: JSON.stringify(currentShipper),file:file,idcardFile:idcard_file
      ,road_file:road_file,open_file:open_file,idcard_back_file:idcard_back_file,tax_file:tax_file,taxpayer_file:taxpayer_file})
  },
  getShipperInfo (shipperId) {
    return Vue.http.post('api/ShipperService/getShipperInfoOfCompany', { shipperId: shipperId})
  },
  saveCompany(currentShipper, file, idcard_file, road_file, open_file, idcard_back_file) {
    return Vue.http.post('api/ShipperService/saveCompany',
      {
        shipperInfo: JSON.stringify(currentShipper),
        file: JSON.stringify(file),
        idcardFile: JSON.stringify(idcard_file),
        roadFile: JSON.stringify(road_file),
        openFile: JSON.stringify(open_file),
        idCardBackFIle: JSON.stringify(idcard_back_file)
      });
  },
  employerAuthSeal (employerId) {
    return Vue.http.post('api/EmployerApiService/employerAuthSeal', { employerId: employerId})
  }
}
